import React, {useState, Fragment, useEffect} from "react";
import {fetchJSON} from "../../utils/ajaxFetch";
import moment from "moment";
import ReactTable from 'react-table-v6';
import "react-table-v6/react-table.css";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'
import styled from "styled-components";

const fetchChanges = domain => fetchJSON(`/domain/load_changes?domain=${domain}`);

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const Cell = styled.div`
${({changed}) => changed ? `background-color: #EF9A9A;` : ``}
${({changed}) => changed ? `color: #424242;` : ``}
margin: -7px -5px;
padding: 7px 5px;
flex: 1;
`;

const TableContainer = styled.div`
    .ReactTable .rt-td {
      display: flex;
    }
    .ReactTable .-loading {
      display: none;    
    }
    /*.ReactTable  {
      border: none;
    }
    .ReactTable .rt-table {
      flex: auto;
      align-items: flex-start;
      border: 1px solid rgba(0,0,0,.1);
    }*/
`;

const DomainHistoryContainer = ({domain}) => {
    const [domainHistoryItems, setDomainHistoryItems] = useState([]);
    const [search, setSearch] = useState(true);
    useEffect(() => {
        fetchChanges(domain).then(({result}) => {
            setDomainHistoryItems(result.data.changes);
            setSearch(false)
        });
    }, []);
    return <div>
        <h1>История по домену</h1>
        {!search && !domainHistoryItems.length && <div>
            Записей не найдено.
        </div>}
        {!!domainHistoryItems.length && <TableContainer>
            <ReactTableFixedColumns
                data={domainHistoryItems}
                columns={[
                    {
                        Header: 'Дата начала',
                        accessor: 'from',
                        Cell: ({value}) => <Fragment>
                            {!value && <Fragment>&mdash;</Fragment>}
                            {value && moment(value).format('ll, LTS')}
                        </Fragment>,
                        fixed: 'left',
                        width: 200
                    },
                    {
                        Header: 'Дата окончания',
                        accessor: 'to',
                        Cell: ({value}) => <Fragment>
                            {!value && <Fragment>&mdash;</Fragment>}
                            {value && moment(value).format('ll, LTS')}
                        </Fragment>,
                        fixed: 'left',
                        width: 200
                    },
                    {
                        Header: 'Имя домена',
                        accessor: 'domain2show',
                        width: 200,
                        Cell: ({value}) => <Cell changed={value.changed}>{value.value}</Cell>
                    },
                    {
                        Header: 'ID домена',
                        accessor: 'uid',
                        Cell: ({value}) => <Cell changed={value.changed}>{value.value}</Cell>
                    },
                    {
                        Header: 'ID договора',
                        accessor: 'aid',
                        Cell: ({value}) => <Cell changed={value.changed}>{value.value}</Cell>
                    },
                    {
                        Header: 'ID владельца',
                        accessor: 'owid',
                        Cell: ({value}) => <Cell changed={value.changed}>{value.value}</Cell>
                    },
                    {
                        Header: 'Автопродление',
                        accessor: 'prolong',
                        Cell: ({value}) => <Cell changed={value.changed}>
                            {!!value.value && `Да`}
                            {!value.value && `Нет`}
                        </Cell>,
                    },
                    {
                        Header: 'NS-серверы',
                        accessor: 'nserver',
                        width: 200,
                        Cell: ({value}) => {
                            const nservers = value.value.split(`\n`);
                            return <Cell changed={value.changed}>{nservers.map((nserver, key) =>
                                <div key={key}>{nserver}</div>
                            )}</Cell>;
                        },
                    },
                    {
                        Header: 'Описание',
                        accessor: 'descr',
                        Cell: ({value}) => <Cell changed={value.changed}>{value.value}</Cell>
                    },
                    {
                        Header: 'Состояние',
                        accessor: 'state',
                        Cell: ({value}) => <Cell changed={value.changed}>{value.value}</Cell>
                    },
                    {
                        Header: 'Может делегироваться',
                        accessor: 'delegate_may',
                        Cell: ({value}) => <Cell changed={value.changed}>
                            {!!value.value && `Да`}
                            {!value.value && `Нет`}
                        </Cell>,
                    },
                    {
                        Header: 'Может редактироваться',
                        accessor: 'update_may',
                        Cell: ({value}) => <Cell changed={value.changed}>
                            {!!value.value && `Да`}
                            {!value.value && `Нет`}
                        </Cell>,
                    },
                    {
                        Header: 'Дата создания',
                        accessor: 'created',
                        Cell: ({value}) => <Cell changed={value.changed}>
                            {!value.value && <Fragment>&mdash;</Fragment>}
                            {value.value && moment(value.value).format('ll, LTS')}
                        </Cell>,
                        width: 200
                    },
                    {
                        Header: 'Дата изменения',
                        accessor: 'changed',
                        Cell: ({value}) => <Cell changed={value.changed}>
                            {!value.value && <Fragment>&mdash;</Fragment>}
                            {value.value && moment(value.value).format('ll, LTS')}
                        </Cell>,
                        width: 200
                    },
                    {
                        Header: 'Дата окончания',
                        accessor: 'reg_till',
                        Cell: ({value}) => <Cell changed={value.changed}>
                            {!value.value && <Fragment>&mdash;</Fragment>}
                            {value.value && moment(value.value).format('ll')}
                        </Cell>,
                        width: 120
                    },
                    {
                        Header: 'Информация',
                        accessor: 'info',
                        Cell: ({value}) => <Cell changed={value.changed}>{value.value}</Cell>
                    },
                    {
                        Header: 'Оператор',
                        accessor: 'operator',
                        Cell: ({value}) => <Cell changed={value.changed}>{value.value}</Cell>
                    },
                    {
                        Header: 'Пользователь БД',
                        accessor: 'pguser',
                        Cell: ({value}) => <Cell changed={value.changed}>{value.value}</Cell>
                    },
                    {
                        Header: 'Сквозной ID',
                        accessor: 'oid',
                        Cell: ({value}) => <Cell changed={value.changed}>{value.value}</Cell>
                    },
                    {
                        Header: 'Скрыть персональную информацию',
                        accessor: 'hide_person',
                        Cell: ({value}) => <Cell changed={value.changed}>
                            {!!value.value && `Да`}
                            {!value.value && `Нет`}
                        </Cell>,
                    },
                    {
                        Header: 'Скрыть email',
                        accessor: 'hide_email',
                        Cell: ({value}) => <Cell changed={value.changed}>
                            {!!value.value && `Да`}
                            {!value.value && `Нет`}
                        </Cell>,
                    },
                    {
                        Header: 'Скрыть телефон',
                        accessor: 'hide_phone',
                        Cell: ({value}) => <Cell changed={value.changed}>
                            {!!value.value && `Да`}
                            {!value.value && `Нет`}
                        </Cell>,
                    },
                    {
                        Header: 'Перехват',
                        accessor: 'intercept_flag',
                        Cell: ({value}) => <Cell changed={value.changed}>
                            {!!value.value && `Да`}
                            {!value.value && `Нет`}
                        </Cell>,
                    },
                ]}
                showPageSizeOptions={false}
                showPagination={false}
                defaultPageSize={domainHistoryItems.length}
                className={`-striped -highlight`}
                style={{maxHeight: `60vh`}}
            />
        </TableContainer>
        }
    </div>;
};

export default DomainHistoryContainer;
