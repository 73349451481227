import React, {Component} from 'react'
import styled from 'styled-components'
import { prop,any,compose,identity,values,map,equals } from 'ramda';

import ExpansionPanel from '../../ExpansionPanel';
import Table from '../../Table';

import { Text } from '../../Typography'
import FlexBlock from '../../FlexBlock'
import Input from '../../Input';
import Textarea from '../../Textarea';
import Select from '../../Select';
import Button from '../../Button'
import { ErrorInput, TipInput } from '../../Error';

import { themeExpansionPanelRegAddNew } from '../../ExpansionPanel'
import { tableRegDns } from '../../Table'

const RegListDnsWrap = styled.div`
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 6px;
    left: -5px;
    display: block;
    width: 11px;
    height: 11px;
    background-color: #D32228;
    border-radius: 50%;
  }
  &:after{
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    display: block;
    width: 1px;
    height: calc(100% - 28px);
    background-image: linear-gradient(to bottom, #DE4547 50%, rgba(255,255,255,0) 0%);
    background-size: 1px 10px;
    background-repeat: repeat-y;
  }
`;

const RegListDnsWrapInner = styled.div`
  padding-left: 24px;
  position: relative;
`;

const RegTableDns = styled.div`
  margin: 0 0 30px;
`;

const RegAddNew = styled.div`
  margin: 25px 0 20px;
`;

class RegListDns extends Component {
  static defaultProps = {
    newDns: {
      host: {val: ''},
      type: {val: ''},
      value: {val: ''},
      priority: {val: ''}
    }
  };

  state = {
    newDns: this.props.newDns,
    regListDns: [...this.props.regListDns],
  };

  render() {
    const hasErrors = compose(any(identity), values,map(prop('error')))(this.state.newDns);
    const hasEmpty = compose(any(equals('')), values, map(prop('val')))(this.state.newDns);
    return (
      <RegListDnsWrap>
        <RegListDnsWrapInner>
          <Text color="#707070">Используются для управления ресурсными записями домена (валидации сертификатов, настройки почты, редиректы, поддоменов, A-записей, MX-записей и др.)</Text>

          {this.state.regListDns.length !== 0 &&  <RegTableDns>
            <Table type='dnsRegistrator'
                   list={this.state.regListDns}
                   theme={tableRegDns}
                   activationEdit={this.activationEdit}
                   changeSelectEdit={this.changeSelectEdit}
                   changeValueRecord={this.changeValueRecord}
                   handlerYesEdit={this.handlerYesEdit}
                   handlerNoEdit={this.handlerNoEdit}
                   handlerYesDelete={this.handlerYesDelete}
            />
          </RegTableDns>}

          <RegAddNew>
            <ExpansionPanel title="Добавление новой записи DNS" theme={themeExpansionPanelRegAddNew}>
              {/*host*/}
              <FlexBlock margin="0 0 20px 0"
                         width="480px"
                         justify-content="space-between"
              >
                <Text>Хост</Text>
                <Input placeholder="mail.ru"
                       setValue={this.addNewDns('host')}
                       value={this.state.newDns.host.val}
                       validationsList={[
                         [str => str.length > 2, 'Менее 3 символов'],
                         [/^[a-z]{2,4}\.[a-z]$/, 'Неверный формат ввода данных']
                       ]}
                       renderError={msg => (
                         <ErrorInput
                           position="0:relative|1000:absolute"
                           left="0:0|1000:calc(100% + 25px)"
                           text-align="0:center|1000:left"
                         >{msg}</ErrorInput>
                       )}
                       tip={<TipInput
                         position="0:relative|1000:absolute"
                         left="0:0|1000:calc(100% + 25px)"
                         text-align="0:center|1000:left"
                       >Пример ввода формы <br /> dd.d </TipInput>}
                />
              </FlexBlock>

              {/*type record*/}
              <FlexBlock margin="0 0 20px 0"
                         width="480px"
                         justify-content="space-between"
              >
                <Text>Тип записи</Text>
                <Select options={[{value: 'TXT', label: 'TXT'}, {value: 'NETXT', label: 'NETXT'}]}
                        changeSelect={this.changeSelect}
                />
              </FlexBlock>

              {/*value*/}
              <FlexBlock margin="0 0 20px 0"
                         width="480px"
                         justify-content="space-between"
              >
                <Text>Значение</Text>
                <Textarea placeholder="Введите значение"
                          value={this.state.newDns.value.val}
                          setValue={this.addNewDns('value')}
                />
              </FlexBlock>

              {/*priority*/}
              <FlexBlock margin="0 0 20px 0"
                         width="480px"
                         justify-content="space-between"
              >
                <Text>Приоритет</Text>
                <Input placeholder="1800"
                       setValue={this.addNewDns('priority')}
                       validationsList={[
                         [str => str.length < 5, 'Не более 4 символов'],
                         [/^\d{1,4}$/, 'Только цифры']
                       ]}
                       renderError={msg => (
                         <ErrorInput
                           position="0:relative|1000:absolute"
                           left="0:0|1000:calc(100% + 25px)"
                           text-align="0:center|1000:left"
                         >{msg}</ErrorInput>
                       )}
                       tip={<TipInput
                         position="0:relative|1000:absolute"
                         left="0:0|1000:calc(100% + 25px)"
                         text-align="0:center|1000:left"
                       >Пример ввода формы <br /> 1800 </TipInput>}
                       value={this.state.newDns.priority.val}/>
              </FlexBlock>

              <FlexBlock
                width="480px"
                justify-content="space-between">
                <FlexBlock flex="1"></FlexBlock>
                <FlexBlock
                  flex="360px 0 0"
                  flex-wrap="wrap"
                >
                  <Button width="100%"
                          dark
                          onClick={this.saveNewDns}
                          disabled={hasErrors || hasEmpty}
                  >Сохранить</Button>
                </FlexBlock>
              </FlexBlock>
            </ExpansionPanel>
          </RegAddNew>
        </RegListDnsWrapInner>
      </RegListDnsWrap>
    )  
  }

  //Actions for RegListTable
  activationEdit = id => () => {
    this.setState({
      regListDns: this.state.regListDns.map(item => {
        if(item.id === id){
          item.edit = true;
          item.previous = {
            host: item.host,
            type: item.type,
            value: item.value,
            priority: item.priority,
          }
        }
        return item;
      })
    })
  };
  changeSelectEdit = id => item => {
    this.setState({
      regListDns: this.state.regListDns.map(el => {
        if(el.id === id) el.type = item.value;
        return el;
      })
    })
  };
  changeValueRecord = (id,name) => (val, err = false) => {
    this.setState({
      regListDns: this.state.regListDns.map(el => {
        if(el.id === id) el[name] = val;
        return el;
      })
    })
  };

  handlerYesEdit = id => () => {
    //validation
    let recordDns = this.state.regListDns.find(el => el.id === id);
    let error = '';
    let objErr = {
      host: {
        regexp: /^\S{1,3}$/,
        msg: 'Хост должен содержать не менее 1 или не более 3 символов. '
      },
      priority: {
        regexp: /^\d{1,4}$/,
        msg: 'Приоритет должен содержать только цифры не менее 1 и не более 4. '
      }
    };
    ['host','priority'].forEach(el => {
      if(!objErr[el].regexp.test(recordDns[el])) error += objErr[el].msg;
    });

    if(!error.length) this.props.msgNotice('DNS запись отредактирована');

    this.setState({
      regListDns: this.state.regListDns.map(item => {
        if(item.id === id){
          delete item.previous;
          delete item.edit;

          if (!error.length) {
            delete item.error;
            //server
            console.log('-----> ', recordDns);
          } else {
            item.error = error
          }
        }
        return item;
      })
    });
  };

  handlerNoEdit = id => () => {
    this.setState({
      regListDns: this.state.regListDns.map(item => {
        if(item.id === id) {
          delete item.edit;
          item.host = item.previous.host;
          item.type = item.previous.type;
          item.value = item.previous.value;
          item.priority = item.previous.priority;
          delete item.previous;
        }
        return item;
      })
    })
  };

  handlerYesDelete = id => () => {
    let recordDns = this.state.regListDns.find(el => el.id === id);
    this.setState({
      regListDns: this.state.regListDns.filter(item => {
        return item.id !== id;
      })
    });
    this.props.msgNotice('DNS запись удалена');

    //server delete recordDns
    console.log('-----> ', recordDns);
  };

  //AddNewDnsRecord
  changeSelect = (item) => {
    this.setState({
      newDns: {
        ...this.state.newDns,
        type: {
          val: item.value,
        }
      }
    })
  };
  addNewDns = name => (val, err = false) => {
    this.setState({
      newDns: {
        ...this.state.newDns,
        [name]: {
          val: val,
          error: err
        }
      }
    })
  };

  saveNewDns = () => {
    let newDnsTransfrom = {};
    Object.keys(this.state.newDns).map(key => newDnsTransfrom[key] = this.state.newDns[key].val);

    //server ---
    console.log('-----> ', newDnsTransfrom);
    //if success
    this.setState({
      newDns: this.props.newDns,
      regListDns: [
        ...this.state.regListDns,
        {
          id: newDnsTransfrom['host'] + (this.state.regListDns.length+1),
          ...newDnsTransfrom
        }
      ]
    });
    this.props.msgNotice('DNS запись добавлена');
  };
}

export default RegListDns