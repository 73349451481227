import React from 'react'
import styled, {ThemeProvider} from 'styled-components'

import { SvgTick, SvgCross } from "../SvgIcon";

const SwitcherWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 80px;
  margin-bottom: 20px;
`;
const SwitcherHandler = styled.div`
  border: 1px solid ${props => props.active ? '#9F9D9D' : '#EDEFF0'};
  border-radius: 5px;
  width: 80px;
  height: 45px;
  position: relative;
  margin-right: 50px;
  cursor: pointer;
`;
const SwitcherSlider = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 39px;
  top: calc(50% - 20px);
  right: 2px;
  background-color: ${props => props.active ? '#9F9D9D' : '#EDEFF0'};
  border-radius: 4px;
  transform: ${props => props.active ? 'translateX(-38px)' : 'translateX(0)'};
  transition: all .3s;
  
  svg{
    path{
      stroke: ${props => props.active ? '#fff' : '#9F9D9D'};
    }
  }
`;
const SwitcherText = styled.div`
  
`;

//THEME
const themeSwitcherStandard = {
  name: 'themeSwitcherStandard'
};

function Switcher(props){
  return (
    <ThemeProvider theme={props.theme}>
      <SwitcherWrap>
        <SwitcherHandler onClick={props.handlerSwitch} active={props.active}>
          <SwitcherSlider active={props.active}>
            {props.active ? <SvgTick/> : <SvgCross/>}
          </SwitcherSlider>
        </SwitcherHandler>
        <SwitcherText>{props.text}</SwitcherText>
      </SwitcherWrap>
    </ThemeProvider>
  )
}

Switcher.defaultProps = {
  theme: themeSwitcherStandard
};

export default Switcher