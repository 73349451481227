import React, {Component} from 'react'
import styled, {css} from 'styled-components'

import ExpansionPanel, { themeExpansionPanelIcon } from "../ExpansionPanel";
import ActionButton from "../ActionButton";
import Textarea, { themeTextareaBordered } from "../Textarea";
import Button from "../Button"

import { SvgPlus } from "../SvgIcon";
// import ActionIcon from "../ActionIcon";

const CommentsWrap = styled.div`
  
`;

const CommentsStep = styled.div`
  display: flex;
`;

const CommentsDate = styled.div`
  flex: 82px 0 0;
`;

const CommentsBlock = styled.div`
  margin-left: 17px;
  flex: 1;
  padding: 30px 0 30px 45px;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    display: block;
    width: 1px;
    background-image: linear-gradient(to bottom, #9F9D9D 50%, rgba(255,255,255,0) 0%);
    background-size: 1px 10px;
    background-repeat: repeat-y;
  }
  
  ${props => props.styles === 'themeCommentsBlockAddComment' && css`
    padding: 0 0 25px 0;
  `}
  
  ${props => props.styles === 'themeCommentsBlockActionButton' && css`
    padding: 0;
  `}
`;

const CommentsPoint = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 10px solid #9F9D9D;
  background-color: #fff;
  position: absolute;
  top: -3px;
  left: 0;
`;

const Comment = styled.div`
  margin-bottom: 30px;
  &:last-child{
    margin-bottom: 0;
  }
`;
const CommentInfo = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
const CommentTime = styled.div`
  color: #9F9D9D;
  margin-right: 10px;
`;
const CommentUser = styled.div`
  
`;
const CommentMessage = styled.div`
  padding: 20px;
  background-color: #F3F5F5;
  border: 1px solid #EDEFF0;
  border-radius: 5px;
`;

class Comments extends Component {
  state = {
    mycomment: '',
    comments: [...this.props.comments],
    showLimit: 2,
    increment: 2
  };


  render() {
    return (
      <CommentsWrap>
        <CommentsStep>
          <CommentsDate>{this.getDateToday()}</CommentsDate>
          <CommentsBlock styles="themeCommentsBlockAddComment">
            <ExpansionPanel theme={themeExpansionPanelIcon(<SvgPlus/>)}
                            title="Добавить комментарий"
                            styles="themeExpansionPanelAddComment"
            >
              <Textarea value={this.state.mycomment}
                        setValue={this.setMycomment}
                        theme={themeTextareaBordered}
                        width="100%"
              />
              {this.state.mycomment.length !== 0 &&
                <Button className="tiny"
                        top="-35px"
                        left="23px"
                        background-color="#fff"
                        onClick={this.saveComment}
                >Сохранить</Button>
              }
            </ExpansionPanel>
          </CommentsBlock>
        </CommentsStep>

        {this.state.comments.slice(0, this.state.showLimit).map(comment =>
          <CommentsStep key={comment.date}>
            <CommentsDate>{comment.date}</CommentsDate>
            <CommentsBlock>
              <CommentsPoint />
              {comment.messages && comment.messages.map(item =>
                <Comment key={item.id}>
                  <CommentInfo>
                    <CommentTime>{item.time}</CommentTime>
                    <CommentUser>{item.user}</CommentUser>
                  </CommentInfo>
                  <CommentMessage>{item.comment}</CommentMessage>
                </Comment>
              )}
            </CommentsBlock>
          </CommentsStep>
        )}

        <CommentsStep>
          <CommentsDate />
          <CommentsBlock styles="themeCommentsBlockActionButton">
            {this.state.showLimit < this.state.comments.length ?
              <ActionButton target="showMore" onClick={this.showMore}/> :
              <ActionButton target="hideAll" onClick={this.hideAll}/>
            }
          </CommentsBlock>
        </CommentsStep>
      </CommentsWrap>
    )
  }

  setMycomment = (val, err) => {
    this.setState({
      mycomment: val
    })
  };

  getDateToday = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //Январь это  0!
    let yyyy = today.getFullYear();

    if(dd < 10) dd = '0' + dd;
    if(mm < 10) mm = '0' + mm;
    return dd + '.' + mm + '.' + yyyy;
  };

  saveComment = () => {
    return this.props.addComment({
      date: this.getDateToday(),
      messages: [
        {
          id: 7,
          time: '15:00',
          user: 'Серегин Санек норм парень',
          comment: this.state.mycomment
        }
      ]
    });
  };

  showMore = () => {
    this.setState({
      showLimit: this.state.showLimit + this.state.increment
    })
  };
  hideAll = () => {
    this.setState({
      showLimit: 2
    })
  }
}

export default Comments