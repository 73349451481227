import React from 'react'
import styled from 'styled-components'

import ActionButtonIcon from './ActionButtonIcon'
import ActionButtonText from './ActionButtonText'

import { SvgEye, SvgArrow, SvgPlus } from './ActionButtonSvg'

import responsiveProps from '../../../utils/responsiveProps';


const ActionButtonWrap = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  left: auto;
  &:hover{
    .action-button__icon{
      box-shadow: inset 0 0 0 2px #DE4547;
      background-color: #CBCBCB;
    }
  }
  
  ${responsiveProps(['left'])}
`;

function ActionButton(props){
  let icon, text = props.children;

  const { target, ...restProps } = props;

  switch(target){
    case 'showMore':
      icon = <SvgEye/>;
      text = 'Посмотреть еще';
      break;

    case 'hideAll':
      icon = <SvgArrow/>;
      text = 'Скрыть все';
      break;

    case 'addMore':
      icon = <SvgPlus/>;
      break;

    default:
      break;
  }

  return (
    <ActionButtonWrap className='action-button' {...restProps}>
      <ActionButtonIcon>{icon}</ActionButtonIcon>
      <ActionButtonText>{text}</ActionButtonText>
    </ActionButtonWrap>
  )
}

export default ActionButton;