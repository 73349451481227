import punycode from 'punycode';
import { fetchJSON } from '../utils/ajaxFetch';

// Ramda
import path from 'ramda/src/path';
import splitEvery from 'ramda/src/splitEvery';

// generateArrayGetParams :: String -> [String] -> String
const generateArrayGetParams =
  k => xs => xs.map(x => `${k}${encodeURIComponent(`[]`)}=${encodeURIComponent(x)}`).join('&');

// generateDomainsSearchQuery :: ({*}) -> String
const generateDomainsSearchQuery = ({ query, category, searchSynonyms = false, searchRelevantZones = false, zones = ''}) => {
  let params = {
    query,
    groups: category,
    searchSynonyms: Number(searchSynonyms),
    searchRelevantZones: Number(searchRelevantZones),
    zones
  };

  return Object.keys(params).map(param => `${param}=${encodeURIComponent(params[param])}`).join('&');
};

const actions = {

  LOAD_USER_DATA_REQUEST: 'LOAD_USER_DATA_REQUEST',
  loadUserDataRequest: () => ({ type: actions.LOAD_USER_DATA_REQUEST }),

  LOAD_USER_DATA_FAILURE: 'LOAD_USER_DATA_FAILURE',
  loadUserDataFailure: (errors = ['Something went wrong']) => ({
    type: actions.LOAD_USER_DATA_FAILURE,
    errors
  }),

  LOAD_USER_DATA_SUCCESS: 'LOAD_USER_DATA_SUCCESS',
  loadUserDataSuccess: payload => ({
    type: actions.LOAD_USER_DATA_SUCCESS,
    payload
  }),

  loadUserData: additionalFields => dispatch => {
    dispatch(actions.loadUserDataRequest());

    const fields = Object.keys(additionalFields).reduce((list, key) => {
      if (additionalFields[key] === true) list.push(key);
      return list;
    }, []).join(',');

    const addFieldsParam = fields ? `?additionalFields=${fields}` : '';

    return fetchJSON(`/order/getUserInfo${addFieldsParam}`)
      .then(resp => {
        let result = {};

        /** accessType */
        result.accessType = path(['result', 'data', 'accessType'], resp) || '';

        /** cart */
        if (path(['result', 'data', 'cart'], resp)) {
          let cart;

          try {
            cart = JSON.parse(resp.result.data.cart);
          } catch (e) {/* ignoring errors */}

          result.cart = cart || void 0;
        }

        /** domains */
        if (path(['result', 'data', 'domains'], resp)) {
          result.domains = resp.result.data.domains.map(domain => ({
            name: punycode.toUnicode(domain.toLowerCase())
          }));
        }

        if (Object.keys(result).length) {
          return dispatch(actions.loadUserDataSuccess(result));
        }

        return Promise.reject(path(['result', 'errors'], resp) || '');
      })
      .catch(reason => dispatch(actions.loadUserDataFailure(reason)));
  },

  /**
   * Cart
   */

  ADD_TO_CART_REQUEST: 'ADD_TO_CART_REQUEST',
  addToCartRequest: () => ({ type: actions.ADD_TO_CART_REQUEST }),

  ADD_TO_CART_FAILURE: 'ADD_TO_CART_FAILURE',
  addToCartFailure: (errors = ['Something went wrong']) => ({
    type: actions.ADD_TO_CART_FAILURE,
    errors
  }),

  ADD_TO_CART_SUCCESS: 'ADD_TO_CART_SUCCESS',
  addToCartSuccess: item => ({
    type: actions.ADD_TO_CART_SUCCESS,
    payload: { item }
  }),

  addToCart: item => dispatch => {
    dispatch(actions.addToCartRequest());

    const data = new FormData();
    data.append('item', JSON.stringify(item));

    return fetchJSON('/order/addToCart', { method: 'post', body: data })
      .then(resp => {
        if (path(['result', 'success'], resp)) {
          return dispatch(actions.addToCartSuccess(item));
        }

        return Promise.reject(path(['result', 'errors'], resp) || '');
      })
      .catch(reason => dispatch(actions.addToCartFailure(reason)));
  },

  REMOVE_FROM_CART_REQUEST: 'REMOVE_FROM_CART_REQUEST',
  removeFromCartRequest: () => ({ type: actions.REMOVE_FROM_CART_REQUEST }),

  REMOVE_FROM_CART_FAILURE: 'REMOVE_FROM_CART_FAILURE',
  removeFromCartFailure: (errors = ['Something went wrong']) => ({
    type: actions.REMOVE_FROM_CART_FAILURE,
    errors
  }),

  REMOVE_FROM_CART_SUCCESS: 'REMOVE_FROM_CART_SUCCESS',
  removeFromCartSuccess: item => ({
    type: actions.REMOVE_FROM_CART_SUCCESS,
    payload: { item }
  }),

  removeFromCart: item => dispatch => {
    dispatch(actions.removeFromCartRequest());

    const data = new FormData();
    data.append('item', JSON.stringify(item));

    return fetchJSON('/order/removeFromCart', { method: 'post', body: data })
      .then(resp => {
        if (resp && resp.result && resp.result.success) {
          return dispatch(actions.removeFromCartSuccess(item));
        }

        return Promise.reject(path(['result', 'errors'], resp) || '');
      })
      .catch(reason => dispatch(actions.removeFromCartFailure(reason)));
  },

  /**
   * Suggested domains
   */

  SEARCH_SUGGESTED_DOMAINS_REQUEST: 'SEARCH_SUGGESTED_DOMAINS_REQUEST',
  searchSuggestedDomainsRequest: () => ({ type: actions.SEARCH_SUGGESTED_DOMAINS_REQUEST }),

  SEARCH_SUGGESTED_DOMAINS_FAILURE: 'SEARCH_SUGGESTED_DOMAINS_FAILURE',
  searchSuggestedDomainsFailure: (errors = ['Something went wrong']) => ({
    type: actions.SEARCH_SUGGESTED_DOMAINS_FAILURE,
    errors
  }),

  SEARCH_SUGGESTED_DOMAINS_SUCCESS: 'SEARCH_SUGGESTED_DOMAINS_SUCCESS',
  searchSuggestedDomainsSuccess: domains => ({
    type: actions.SEARCH_SUGGESTED_DOMAINS_SUCCESS,
    payload: { domains }
  }),

  searchSuggestedDomains: (query, limit = 4) => dispatch => {
    const searchQuery = generateDomainsSearchQuery({
      query,
      category: 2,
      searchSynonyms: true,
      searchRelevantZones: true
    });

    let domains;
    let domainsSplitBy10;

    let resultDomains = [];

    let dataSearchAttempts = 0;

    const DATA_SEARCH_ATTEMPTS_LIMIT = 4;
    const DOMAINS_LIMIT = limit;

    dispatch(actions.searchSuggestedDomainsRequest());

    return fetchJSON(`/order/domainSearch?${searchQuery}`)
      .then(resp => {
        if (!path(['result', 'data', 'result'], resp)) {
          return Promise.reject(path(['result', 'errors'], resp) || '');
        }

        const result = path(['result', 'data', 'result', 'domains'], resp) || [];
        let queryDomains = path(['result', 'data', 'result', 'queryDomains'], resp) || [];
        // let synonyms = resp.result.data.result.synonyms || [];
        const relevantZones = path(['result', 'data', 'result', 'relevantZones'], resp) || [];

        queryDomains = queryDomains.map(punycode.toASCII);

        domains = result
          .filter(name => !queryDomains.includes(name))
          .map(name => ({
            name: punycode.toUnicode(name),
            ASCIIName: name,
            tld: name.split('.').pop()
          }));

        let relevantZonesForSort = relevantZones.slice(0, parseInt(2 * (DOMAINS_LIMIT / 4), 10));
        const relevantZonesDomains = domains
          .filter(d => relevantZonesForSort.includes(d.tld))
          // sort
          .map(x => ({ x, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(a => a.x)
          // pick distinct tld's
          .reduce((res, domain) => {
            if (relevantZonesForSort.includes(domain.tld)) {
              res.push(domain);
              relevantZonesForSort = relevantZonesForSort.filter(zone => zone !== domain.tld);
            }
            return res;
          }, [])
          .map(domain => domain.ASCIIName);

        // random sort & pulling relevant zones to top
        domains = domains
          .map(x => {
            let sort = Math.random();

            if (relevantZonesDomains.includes(x.ASCIIName)) {
              sort += 1;
            }

            return { x, sort };
          })
          .sort((a, b) => b.sort - a.sort)
          .map(a => a.x);

        domainsSplitBy10 = splitEvery(10, domains);

        // promise chain
        return domainsSplitBy10.reduce((promise, pack) => {
          return promise.then(() => {
            if (resultDomains.length >= DOMAINS_LIMIT || dataSearchAttempts >= DATA_SEARCH_ATTEMPTS_LIMIT) {
              return Promise.reject('Required amount achieved or no attempts left');
            }

            const domainsPack = pack.map(({ ASCIIName }) => ASCIIName);

            dataSearchAttempts++;

            const queryParams = generateArrayGetParams('names')(domainsPack);
            // add user id
            return fetchJSON(`/order/domainDataSearch?${queryParams}`)
              .then(resp => {
                let result = path(['result', 'data', 'result', 'domain'], resp);
                if (!result) {
                  return Promise.reject('Unable to fetch domains data');
                }

                result = result
                  .map(domain => {
                    // casting to boolean
                    domain.available = [1, true, 'available'].includes(domain.available);
                    domain.supported = [1, true, 'supported'].includes(domain.supported);
                    return domain;
                  })
                  .filter(domain => domain.available)
                  .slice(0, (DOMAINS_LIMIT - resultDomains.length));

                dispatch(actions.searchSuggestedDomainsDataSuccess(result));

                resultDomains = [...resultDomains, ...result];
              })
              .catch(reason => console.warn(reason)); // TODO: refactor

          });
        }, Promise.resolve())
          .catch(() => {/* catching skip condition */})
          .then(() => {
            if (resultDomains.length) {
              return dispatch(actions.searchSuggestedDomainsSuccess());
            } else {
              return Promise.reject('No domains found');
            }
          });

      })
      .catch(reason => dispatch(actions.searchSuggestedDomainsFailure(reason)));
  },

  SEARCH_SUGGESTED_DOMAINS_DATA_SUCCESS: 'SEARCH_SUGGESTED_DOMAINS_DATA_SUCCESS',
  searchSuggestedDomainsDataSuccess: domains => ({
    type: actions.SEARCH_SUGGESTED_DOMAINS_DATA_SUCCESS,
    payload: { domains }
  }),

};

export default actions;