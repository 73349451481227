import React, {Component,Fragment} from 'react'

// import Breadcrumb from '../../components/all/Breadcrumb'
import Table from '../../components/all/Table'
import TableTariffs from '../../components/all/TableTariffs'

import Wrapper from '../../components/all/Wrapper'
import Box from '../../components/all/Box'
import Headline from '../../components/all/Headline'
import FlexBlock from '../../components/all/FlexBlock'
import Link from '../../components/all/Link'
import Popup from "../../components/all/Popup";
import Notification from "../../components/all/Notification";

import { fetchJSON } from '../../utils/ajaxFetch';

class TariffsContainer extends Component {
  state = {
    additionalService: [],
    notification: [],
    popup: {
      show: false,
      data: {
        func: '',
        content: '',
        body: ''
      }
    }
  };

  //все обращения к серверу
  componentDidMount() {
    fetchJSON(`/imp/api/getcatalog`)
      .then((data) => {
        this.setState({
          user: data.user,
          allZones: data.tariffZones,
          allServices: data.tariffServices
        });
      })
      .catch(function (error) {
        console.log('error', error)
      });
  }

  render() {
    return (
      <Fragment>
        <Notification notice={this.state.notification}/>
        {/*<Breadcrumb activeLink={4} />*/}

        {/*<Headline size='xl' color='red'>Тарифы</Headline>*/}
        {this.state.user === 'admin' &&
          <Wrapper>
            <Headline view="admin">Система настройки и управления разделом Тарифы</Headline>
            <FlexBlock justify-content="space-between" align-items="stretch" flex-wrap="wrap">
              <Box>
                <Headline weight="semibold" size="xl">Тарифы и услуги</Headline>
                <Link href="/management/tariff" view="common">Управление тарифами</Link>
                <Link href="/management/service" view="common">Управление услугами</Link>
                <Link href="/management/defaults" view="common">Настройка тарифов по умолчанию для типов клиентов</Link>
                <Link href="/management/markup" view="common">Настройка наценок на услуги тарифов</Link>
              </Box>
              <Box>
                <Headline weight="semibold" size="xl">Доменные зоны</Headline>
                <Link href="/management/tld" view="common">Управление доменными зоны</Link>
                <Link href="/tld/group/list" view="common">Управление категориями доменных зон</Link>
              </Box>
              <Box>
                <Headline weight="semibold" size="xl">Валюты и НДС</Headline>
                <Link href="/management/currency" view="common">Управление курсом валют</Link>
                <Link href="/management/taxrate" view="common">Управление НДС на цены регистраторов</Link>
              </Box>
            </FlexBlock>
          </Wrapper>
        }
        {this.state.user === 'client' &&
          <Fragment>
            <Wrapper>
              <Headline>Тарифы на доменные зоны</Headline>
              <TableTariffs zones={this.state.allZones}/>
            </Wrapper>

            <Wrapper>
              <Headline>Дополнительные услуги</Headline>
              <Table type='additionalService' list={this.state.allServices} handlerClick={this.orderAdditionService} />
            </Wrapper>
          </Fragment>
        }

        <Popup showPopup={this.state.popup.show}
               closePopup={this.closePopup}
               func={this.state.popup.data.func}
               content={this.state.popup.data.content}
               body={this.state.popup.data.body}
        />
      </Fragment>
    )
  }

  //активация промо-пакета
  handlerActivatePacket = id => () => {
    const formData = new FormData();
    formData.append('id', id);
    fetchJSON(`/imp/api/servicePackageActivation`, {method: 'post', body: formData})
      .then((data) => {
        let head, content, button, func;
        if(data.error) {
          if(data.errorDetail === 'balance'){
            head = data.head;
            content = data.content;
            button = 'Пополнить баланс';
            func = () => {
              window.location.href = data.href; //пропиши ссылку которая тебе нужна вместо #
            }
          } else if(data.errorDetail === 'packetActive'){
            head = 'Пакет уже активен';
            content = data.content;
            button = 'Закрыть';
            func = () => {
              this.closePopup();
            }
          }
        } else {
          head = data.head;
          content = data.content;
          button = 'Активировать';
          func = this.activatePacket(id);
        }

        this.showPopup('apply', func, {head, content, button})()
      })
      .catch(function (error) {
        console.log('error', error)
      });
  };

  activatePacket = id => () => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('activate', true);
    fetchJSON(`/imp/api/servicePackageActivation`, {method: 'post', body: formData})
        .then((data) => {
          this.msgNotice('Пакет активирован');
          this.closePopup();
          this.setState({
            packets: data.packets,
            allZones: data.tariffZones
          });
        })
        .catch(function (error) {
          //console.log('error', error)
        });
  };

  showPopup = (content, func, body) => () => {
    this.setState({
      popup: {
        show: true,
        data: {content, func, body}
      }
    })
  };

  closePopup = () => {
    this.setState({
      popup: {
        show: false,
        data: {content: '', func: '', body: ''}
      }
    })
  };

  msgNotice = msg => {
    const timerId = setTimeout(() => this.removeNotice(timerId), 3000);

    this.setState({
      notification: [
        ...this.state.notification,
        {msg, timerId}
      ]
    });
  };
  removeNotice = timerId => {
    this.setState({
      notification: this.state.notification.filter(notice => timerId !== notice.timerId)
    });
  };

  orderAdditionService = () => {
    // запрос на сервер
    // fetch()
    console.log('-----> ', 'oserAdd');
  };
  orderSslCertificate = () => {
    // запрос на сервер
    // fetch()
    console.log('decewcewcewcewce');
  }
}

export default TariffsContainer