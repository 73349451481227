import actions from '../actions';

const cart = (state = [], { type, payload }) => {
  switch (type) {
    case actions.LOAD_USER_DATA_SUCCESS:
      return payload.cart || state;

    case actions.ADD_TO_CART_SUCCESS: {
      // already in cart
      if (state.find(item => item.name === payload.item.name)) return state;
      return [ ...state, payload.item ];
    }

    case actions.REMOVE_FROM_CART_SUCCESS:
      return state.filter(item => item.name !== payload.item.name);

    default:
      return state;
  }
};

export default cart;