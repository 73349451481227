import styled from 'styled-components'

import responsiveProps from '../../../utils/responsiveProps';

export const Paragraph = styled.p`
  font: 400 16px 'OpenSans';
  text-align: ${props => props.text_align ? props.text_align : 'left'};
  color: ${props => props.color ? props.color : '#4A4A58'};
  margin-bottom: ${props => props.mb ? props.mb : '1em'};
  margin-top: ${props => props.mt ? props.mt : '0'};
  &:last-child{
    margin-bottom: 0;
  }
`;

export const Text = styled.span`
  font-size: ${props => props.size ? props.size : '16px'};
  font-weight: ${props => props.weight ? props.weight : 400};
  font-family: 'OpenSans';
  text-align: ${props => props.text_align ? props.text_align : 'left'};
  color: ${props => props.color ? props.color : '#4A4A58'};
  // margin-bottom: ${props => props.mb ? props.mb : '1em'};
  // margin-top: ${props => props.mt ? props.mt : '0'};
  
  ${responsiveProps(['font-weight','font-size','color','cursor','display','margin','text-align'])}
`