import React, {Component} from 'react'
import styled, { ThemeProvider, css } from 'styled-components'
import responsiveProps from '../../../utils/responsiveProps';

import Button from '../Button'

import { SvgDelete } from "../SvgIcon";

const TextareaWrap = styled.div`
  position: relative;
  flex: 360px 0 0;
  width: 360px;

  ${Button}{
    position: absolute;
    line-height: 0;
    top: calc(50% - 3px);
    right: -20px;
  }
  
  textarea{
    height: 130px;
    padding: 10px 20px 12px;
    border: 1px solid #CBCBCB; 
    outline: none;
    resize: vertical;
    background-color: inherit;
    width: 100%;
    &::placeholder{
      color: #CBCBCB;
    }
    &:focus{
      border-color: #464646;
    }
    &:disabled{
      resize: none;
      border: none;
      padding: 0;
      background-color: inherit;
      //height: auto;
    }
    
    ${responsiveProps(['padding'])}
  }
  
  ${props => props.theme.name === 'themeTextareaTiny' && css`
    ${Button}{
      display: none;
    }
    textarea{
      resize: none;
      border: none;
      padding: 0;
      &:disabled{
        background-color: inherit;
        //height: auto;
      }
    }
  `}
  
  ${props => props.theme.name === 'themeTextareaBordered' && css`
    textarea{
      resize: none;
      border-radius: 5px;
    }
  `}
  
  ${responsiveProps(['flex','width'])}
`;

//THEME
export const themeTextareaStandard = {
  name: 'themeTextareaStandard'
};
export const themeTextareaTiny = {
  name: 'themeTextareaTiny'
};
export const themeTextareaBordered = {
  name: 'themeTextareaBordered'
};

class Textarea extends Component {
  static defaultProps = {
    theme: themeTextareaStandard
  }

  state = {
    errorMsg: []
  };

  render() {
    return (
      <ThemeProvider theme={this.props.theme}>
        <TextareaWrap {...this.props}>
        <textarea placeholder="Введите значение"
                  value={this.props.value}
                  onChange={this.onChangeValue}
                  disabled={this.props.disabled}
                  padding={this.props.padding}
        />
          {!!this.props.value.length && !this.props.hideButton && <Button className="tiny" onClick={() => {this.props.setValue('')}}>
            <SvgDelete/>
          </Button>}
        </TextareaWrap>
      </ThemeProvider>
    )
  }

  onChangeValue = e => {
    const errorMsg = (this.props.validationsList || []).map(([pred, msg]) => {
      if (e.target.value === '') return '';
      if(typeof pred === "function"){
        return pred(e.target.value) ? '' : msg;
      }
      return pred.test(e.target.value) ? '' : msg;
    }).filter(Boolean);
    this.setState({errorMsg});
    return this.props.setValue(e.target.value, !!errorMsg.length)
  }
}

export default Textarea