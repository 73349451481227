import styled from 'styled-components'
import responsiveProps from '../../../utils/responsiveProps';

const FlexBlock = styled.div`
  display: flex;
  width: auto;
  flex: none;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: -10px;
  
  &.dns_added-info{
    padding-left: 30px;
    background-image: linear-gradient(to bottom, #DE4547 50%, rgba(255,255,255,0) 0%);
    background-position: left;
    background-size: 1px 10px;
    background-repeat: repeat-y;
  }
  
  ${responsiveProps(['width','flex','flex-wrap','flex-direction','justify-content','align-items','margin','display','text-align','display'])}
`;

export default FlexBlock;