import React, {Component} from 'react'
import styled from 'styled-components'

import Link, { themeLinkTiny } from '../Link'

import { SvgPen,SvgDeleteCart } from "../SvgIcon"

const ActionIconSvg = styled.div`
  cursor: pointer;
  svg{
    path, rect{
      fill: ${props => props.active ? '#D32228' : '#707070'}
    }
  }
`;

const ActionIconWrap = styled.div`
  position: relative;
  display: ${props => props.active ? 'flex' : 'inline-flex'};
  align-items: center;
  margin-right: 10px;
  margin-bottom: 5px;
  &:hover{
    ${ActionIconSvg}{
      svg{
       path, rect{
        fill: #D32228;
       }
      }
    }
  }
`;

const ActionIconTip = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
`;
const ActionIconTipText = styled.span`
  margin-right: 10px;
`;
const ActionIconTipButtons = styled.div`
  display: flex;
  align-items: center;
  div:first-child{
    margin-right: 15px;
  }
`;

class ActionIcon extends Component {
  state = {
    showTip: false
  };

  render() {
    let textTip, icon;

    switch(this.props.theme){
      case 'actionEdit':
        icon = <SvgPen/>;
        textTip = 'Подтвердить изменения?';
        break;
      case 'actionDelete':
        icon = <SvgDeleteCart/>;
        textTip = 'Подтверждаете удаление?';
        break;

      default: break;
    }

    return (
      <ActionIconWrap active={this.state.showTip}
                      onClick={() => {
                        if(!this.state.showTip){
                          this.setState({
                            showTip: true
                          });
                        }
                        if(this.props.onClickAction){
                          this.props.onClickAction()
                        }
                      }}>
        <ActionIconSvg active={this.state.showTip}>
          {icon}
        </ActionIconSvg>
        {this.state.showTip && <ActionIconTip>
          <ActionIconTipText>{textTip}</ActionIconTipText>
          <ActionIconTipButtons>
            <Link theme={themeLinkTiny}
                  onClick={(e) => {
                    e.stopPropagation();
                    if(this.props.handlerYes) this.props.handlerYes();
                    this.setState({
                      showTip: false
                    });
                  }}
                  target={this.props.theme === 'actionEdit'}
            >Да</Link>
            <Link theme={themeLinkTiny}
                  onClick={(e) => {
                    e.stopPropagation();
                    if(this.props.handlerNo) this.props.handlerNo();
                    this.setState({
                      showTip: false
                    });
                  }}
            >Нет</Link>
          </ActionIconTipButtons>
        </ActionIconTip>}
      </ActionIconWrap>
    )
  }
}

export default ActionIcon