import React, {Component} from 'react'
import styled from 'styled-components'

import ActionButton from '../ActionButton'
import Button from '../Button'
import formatPriceFloat from '../../../utils/formatPrice';

import { SvgDelete,SvgSearch,SvgTick } from '../SvgIcon'

const TableTariffsWrap = styled.div`
  
`;

const TableTariffsRow = styled.div`
  background-image: linear-gradient(to right, #DE4547 28%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 7px 1px;
  background-repeat: repeat-x;
  
  display: flex;
  height: 100%;
`;
const TableTariffsColumn = styled.div`
  flex: 1;
  border-right: 1px solid #F1F3F4;
  
  ${TableTariffsRow}{
    background: none;
  }
  
  &.tt-column{
    &:last-child{
      border-right: 0;
    }
    &--left{ 
      padding-left: 26px; 
      padding-right: 15px;
      flex: 280px 0 0;
      .headline{
        text-align: left;
      }
    }
    &--domain{
      flex: 100px 0 0;
    }
    &--zones{
      padding-left: 26px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      
      .red-zone{
        color: #DE4547;
        font-weight: 600;
      }
      
      svg{
        path{
          stroke: #de4547;
        }
      }
    }
    &--no-paddings{
      padding: 0 !important;
    }
    
    .old-price{
      color: #9F9D9D;
      text-decoration: line-through;
      display: inline-block;
      margin-right: 10px;
      font-size: 14px;
    }
  }
  &.keep-zone{
    font-weight: 600;
    span{
      font-weight: 600;
      color: #DE4547;
    }
  }
`;
const TableTariffsHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #DE4547;
  position: sticky;
  top: 0;
  background-color: #fff;
  
  .tt-column{
    border-right: none;
    &:not(:first-child){
      background-color: rgba(237,239,240,.2);
    }
    &:nth-child(3){
      background-color: rgba(222,69,71,.1);
    }
  }
`;
const TableTariffsHeadline = styled.div` 
  text-align: center;
  padding: 21px 0 5px;
  span{
    color: #9F9D9D;
  }
`;


const TableTariffsBody = styled.div`
  .tt-column{
    padding: 15px 0;
    text-align: center;
  }
  
  & > .tt-row{
    & > .tt-column{
      &:not(:first-child){
        background-color: rgba(237,239,240,.2);
      }
      &:nth-child(3){
        background-color: rgba(222,69,71,.1);
        .tt-column{
          border-right-color: #fff;
        }
      }  
    }
  }
`;

const TableTariffsSearch = styled.div`
  display: flex;
  margin: 3px 0 11px;
  align-items: flex-end;
  justify-content: flex-end;
  height: 21px;
`;
const TableTariffsInput = styled.div`
  flex: 1;
  position: relative;
  input{
    border: none; 
    border-bottom: 1px solid #9F9D9D;
    padding: 0 14px 0 0;
    outline: none;
    width: 100%;
    &::placeholder{
      color: #9F9D9D;
    }
  }
  button{
    position: absolute;
    right: 3px;
  }
`;
const TableTariffsLoupe = styled.div` 
  line-height: .8;
  cursor: pointer;
  margin-left: 5px;
  svg .svg-hover{
    stroke: ${props => props.active ? '#d32228' : '#9f9d9d'};
  }
  &:hover{
    svg .svg-hover{
      stroke: #D32228;
    }
  }
`;

const TableTariffsOptions = styled.div` 
  margin-top: 23px;
  display: flex;
`;

class TableTariffs extends Component {
  limit = 10;

  state = {
    search: '',
    showSearch: false,
    showMore: false,
    showOptions: true,
    limit: this.limit
  };

  render() {
    const filteredZones = this.filteredZones();
    const zones = filteredZones.slice(0, this.state.limit);

    return (
      <TableTariffsWrap>
        <TableTariffsHeader>
          <TableTariffsColumn className="tt-column tt-column--left">
            <TableTariffsHeadline className="headline">Доменная зона</TableTariffsHeadline>
            <TableTariffsSearch>
              {this.state.showSearch && <TableTariffsInput>
                <input type="text" value={this.state.search} onChange={this.setSearch}/>
                <Button className="tiny" onClick={this.clearSearch}>
                  <SvgDelete/>
                </Button>
              </TableTariffsInput>}
              <TableTariffsLoupe onClick={this.handleSearch} active={this.state.showSearch}>
                <SvgSearch/>
              </TableTariffsLoupe>
            </TableTariffsSearch>
          </TableTariffsColumn>

          <TableTariffsColumn className="tt-column tt-column--domain">
            <TableTariffsHeadline className="headline">Доменов</TableTariffsHeadline>
          </TableTariffsColumn>
          {['Регистрация', 'Продление', 'Перенос'].map((el) =>
            <TableTariffsColumn key={el} className="tt-column">
              <TableTariffsHeadline className="headline">{el} <span>(&#8381; / год)</span></TableTariffsHeadline>

            </TableTariffsColumn>
          )}

        </TableTariffsHeader>


        <TableTariffsBody>
          {zones.map(item => <TableTariffsRow className='tt-row' key={item.id}>
            <TableTariffsColumn className={`tt-column tt-column--left tt-column--zones ${item.keep && 'keep-zone'}`}>
              <span>.{item.zone}</span>
              {item.keep && <SvgTick/>}
            </TableTariffsColumn>
            <TableTariffsColumn className={`tt-column tt-column--domain`}>
              <span>{item.count}</span>
            </TableTariffsColumn>
            {['domain_add', 'domain_prolong', 'domain_transfer'].map((el) =>
              <TableTariffsColumn key={item.id + el} className="tt-column tt-column--no-paddings">
                <TableTariffsRow className="row-inner">
                  <TableTariffsColumn className={`tt-column ${item.keep && 'keep-zone'}`}>
                    {formatPriceFloat(item[el].price)}
                  </TableTariffsColumn>

                </TableTariffsRow>
              </TableTariffsColumn>
            )}
          </TableTariffsRow>)}
        </TableTariffsBody>

        {filteredZones.length > this.limit && <TableTariffsOptions>
          <ActionButton target={this.state.showMore ? 'hideAll' : 'showMore'}
                        onClick={this.state.showMore ? this.toggleShow(this.limit) : this.toggleShow(999999)}
          />
        </TableTariffsOptions>}

      </TableTariffsWrap>
    )
  }

  setSearch = (e) => {
    this.setState({
      search: e.target.value
    })
  };

  clearSearch = () => {
    this.setState({
      search: ''
    })
  };

  handleSearch = () => {
    this.setState({
      showSearch: !this.state.showSearch
    })
  };

  toggleShow = (limit) => () => {
    this.setState({
      limit: limit,
      showMore: !this.state.showMore
    });
  };

  filteredZones = () => {
    if (this.state.search !== ''){
      let regex = new RegExp("^" + this.state.search);
      return this.props.zones.filter(item => regex.test(item.zone))
    } else {
      return [...this.props.zones];
    }
  }
}

export default TableTariffs