import React from 'react'
import styled from 'styled-components'

const BoxWrap = styled.div`
  padding: 20px 40px;
  border: 1px solid #D8D8D8;
  border-radius: 0.3125em;
  flex: 460px 1 1;
  margin: 10px;
`

function Box(props){
  return (
    <BoxWrap>
      {props.children}
    </BoxWrap>
  )
}

export default Box