import styled, {css} from 'styled-components'

import responsiveProps from '../../../utils/responsiveProps';

const contEl = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  padding: 0 15px;
  color: #fff;
  border-radius: 5px;
  line-height: 1;
  z-index: 2;
  position: absolute;
  left: calc(100% + 25px);
  display: flex;
  align-items: center;
  
  &:before{
    content: '';
    position: absolute;
    top: calc(50% - 15px);
    right: calc(100% - 15px);
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
  }
`;

export const ErrorInput = styled(contEl)`
  background-color: #D32228;
  &:before{
    border: 10px solid #D32228;
  }
  
  ${props => props.theme === 'themeErrorInputSmall' && css`
    padding: 5px 15px;
    height: 30px;
  `}
  
  ${responsiveProps(['position','left','text-align'])}
`;

export const TipInput = styled(contEl)`
  background-color: #EDEFF0;
  color: #9F9D9D;
  line-height: 1.2;
  &:before{
    border: 10px solid #EDEFF0;
  }
  
  ${responsiveProps(['position','left','text-align'])}
`;