import React from 'react'
import styled from 'styled-components'

const ActionButtonTextWrap = styled.div`
  
`;

function ActionButtonText(props){
  return (
    <ActionButtonTextWrap className='action-button__text'>
      {props.children}
    </ActionButtonTextWrap>
  )
}

export default ActionButtonText