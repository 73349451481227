import actions from '../actions';

const suggestedDomains = (state = [], { type, payload }) => {
  switch (type) {
    // case actions.SEARCH_SUGGESTED_DOMAINS_SUCCESS:
    case actions.SEARCH_SUGGESTED_DOMAINS_DATA_SUCCESS:
      return [
        ...state,
        ...payload.domains.reduce((res, domain) => { // removing duplicates
          if (!state.find(({ name }) => name === domain.name) && !res.find(({ name }) => name === domain.name)) {
            res.push(domain);
          }
          return res;
        }, [])
      ];

    default:
      return state;
  }
};

export default suggestedDomains;