import React, { Fragment } from 'react'
import styled, {css, ThemeProvider} from 'styled-components'

import ActionIcon from '../ActionIcon'
import Input from "../Input";
import Textarea from "../Textarea";
import Select from '../Select';
import formatPriceFloat from '../../../utils/formatPrice';

const TableWrap = styled.div`
  ${props => props.theme.name === 'tableRegDns' && css`
    margin-left: -24px;
  `}
`;

const TableColumn = styled.div` 
  border-left: 1px solid #F1F3F4;
  text-align: center;
  padding: 13px 0;
  flex: 270px 0 0;
  word-wrap: break-word;
  
  &:first-child{
    border-left: none;
    text-align: left;
    padding-left: 27px;
    flex: 1;
    max-width: none; 
  }
  &:nth-last-child(2){
    background-color: rgba(237,239,240,.2);
  }
  
  @media (max-width: 1400px){
    flex: 1;
  }
  
  ${props => props.theme.name === 'tableRegDns' && css`
    padding: 13px 10px;
    text-align: left;
    flex: none;
    &:nth-child(1){
      flex: 1;
    }
    &:nth-child(2){
      width: 17%;
    }
    &:nth-child(3){
      width: 27%;
    }
    &:nth-child(4){
      width: 11%;
      background: none;
    }
    &:nth-child(5){
      width: 25%;
    }
  `}
`
const TableRow = styled.div`
  display: flex;
  position: relative;
  background-image: linear-gradient(to right, #DE4547 28%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 7px 1px;
  background-repeat: repeat-x;
  background-color: ${props => props.error ? 'rgba(222,69,71,.1)' : '#fff'};
`

const TableRowError = styled.div`
  position: absolute;
  top: calc(100% - 1px);
  width: 100%;
  padding: 14px 24px;
  background-color: #D32228;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  z-index: 9;
  &:before{
    content: '';
    position: absolute;
    top: -14px;
    left: calc(50% - 15px);
    display: block;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    border: 10px solid #D32228;
  }
`

const TableHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #DE4547;
  
  ${TableColumn}{
    border: none;
    padding: 25px 0 15px;
    &:first-child{
      padding-left: 27px;
    }
    span{
      color: #B4B2B2;
    }
  }
  
  ${props => props.theme.name === 'tableRegDns' && css`
    ${TableColumn}{
      padding: 20px 10px 13px;
      color: #9F9D9D;
      &:first-child{
        padding-left: 27px;
      }
    }
  `}

`
const TableBody = styled.div`
  
`

//THEME
export const tableStandard = {
  name: 'tableStandard'
};
export const tableRegDns = {
  name: 'tableRegDns'
};

function Table(props){
  let headerTable;
  let row;

  switch(props.type){
    case('additionalService'):
      headerTable = ['Услуга', <Fragment>Стоимость</Fragment>];
      break;
    case('sslCertificate'):
      headerTable = ['Сертификат','Тип сертификата', <Fragment>Стоимость разовая<br/> <span>руб</span></Fragment>];
      row = ['name','type','price'];
      break;
    case('dnsRegistrator'):
      headerTable = ['Хост','Тип записи','Значение','Приоритет','Действия'];
      row = ['host','type','value','priority'];
      break;

    default:
      break;
  }

  return (
    <ThemeProvider theme={props.theme}>
      <TableWrap>
        <TableHeader>
          {headerTable.map(item => <TableColumn key={item}>{item}</TableColumn>)}
        </TableHeader>
        <TableBody>
          {(() => {
            if(props.type === 'additionalService'){
              return props.list.map(item => (
                <TableRow key={item.id}>
                    <TableColumn>{item.name}</TableColumn>
                    <TableColumn>{formatPriceFloat(item.price)} &#8381; / {item.period_type}</TableColumn>
                </TableRow>
              ))
            } else if(props.type !== 'dnsRegistrator'){
              return props.list.map(item => (
                <TableRow key={item.id}>
                  {row.map(el => <TableColumn key={el}>{item[el]}</TableColumn>)}
                  {/* button && <TableColumn><Button className="tiny" onClick={props.handlerClick}>Заказать</Button></TableColumn>*/}
                </TableRow>
              ))
            } else {
              return props.list.map(item => (
                <TableRow key={item.id} error={item.error && item.error.length !== 0}>
                  {row.map(el => <TableColumn key={el}>{
                    (() => {
                      switch(el){
                        case 'host':
                        case 'priority':
                          return <Input flex="100% 0 0"
                                        disabled={!item.edit}
                                        setValue={props.changeValueRecord(item.id, el)}
                                        value={item[el]}
                                        hideButton
                                        padding="5px"
                          />;

                        case 'value':
                          return <Textarea disabled={!item.edit}
                                           setValue={props.changeValueRecord(item.id, 'value')}
                                           width="100%"
                                           value={item[el]}
                                           hideButton
                                           padding="5px !important"
                          />;

                        case 'type':
                          return <Select value={{value: item[el], label: item[el]}}
                                         changeSelect={props.changeSelectEdit(item.id)}
                                         disabled={!item.edit}
                                         options={[{value: 'TXT', label: 'TXT'}, {value: 'NETXT', label: 'NETXT'}, {value: 'TXTTVW', label: 'TXTTVW'}, {value: 'TXT', label: 'TXT'}]}
                                         themeSelect="tiny"
                          />;

                        default: break;
                      }
                    })()
                  }</TableColumn>)}
                  <TableColumn>
                    <ActionIcon theme="actionEdit"
                                onClickAction={props.activationEdit(item.id)}
                                handlerYes={props.handlerYesEdit(item.id)}
                                handlerNo={props.handlerNoEdit(item.id)}
                    />
                    <ActionIcon theme="actionDelete" handlerYes={props.handlerYesDelete(item.id)}/>
                  </TableColumn>
                  {item.error && <TableRowError>{item.error}</TableRowError>}
                </TableRow>
              ))
            }
          })()}
        </TableBody>
      </TableWrap>
    </ThemeProvider>
  )
}

Table.defaultProps = {
  theme: tableStandard
};

export default Table