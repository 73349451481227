import React, {Component} from 'react'
import styled, {keyframes}  from 'styled-components'

const showNotice = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

const NotificationWrap = styled.div`
  position: fixed;
  right: 20px;
  top: 20px;
`;
const NotificationMsg = styled.div`
  padding: 20px;
  border-radius: 5px;
  background-color: #707070;
  color: #fff;
  margin-bottom: 10px;
  animation: ${showNotice} .5s;
`;

class Notification extends Component {
  render() {
    return (
      <NotificationWrap {...this.props}>
        {this.props.notice.map(note => (
          <NotificationMsg key={note.timerId} >{note.msg}</NotificationMsg>
        ))}
      </NotificationWrap>
    )  
  }
}

export default Notification