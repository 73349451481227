import React, {useEffect} from 'react'
import styled from 'styled-components'
import ReactSiema from 'react-siema'

const SliderWrap = styled.div`
  max-width: 1370px;
  position: relative;
  
  img{
    width: 100%;
  }
`;


function Slider(props){
  let refSlider = React.createRef();

  const options = {
    duration: 1500,
    loop: true
  };

  useEffect(() => {
    setInterval(() => {
      refSlider.current.next();
    }, 17000)
  },[]);

  return (
    <SliderWrap className="SliderSiema">
      <ReactSiema {...options} ref={refSlider}>
        <a className="slide-siema" href="https://hoster.ru/?utm_source=BeDomenus&utm_medium=billing&utm_campaign=Billing_Domenus" target="_blank" rel="noopener noreferrer">
          <img src="/images/hoster__ssd-hosting1.jpg" alt=""/>
        </a>
        <a className="slide-siema" href="https://datahouse.ru/promo/arenda-servera-e5645/?utm_source=be_domenus&utm_medium=banner&utm_campaign=ded_6000" target="_blank" rel="noopener noreferrer">
          <img src="/images/datahouse__server6000.jpg" alt=""/>
        </a>
        <a className="slide-siema" href="https://forms.gle/TSfmaPmq2U1LzZ516" target="_blank" rel="noopener noreferrer">
          <img src="/images/business-free.jpg" alt=""/>
        </a>
      </ReactSiema>

      {/*<SliderButton prev onClick={() => refSlider.current.prev()}/>
      <SliderButton next onClick={() => refSlider.current.next()}/>*/}
    </SliderWrap>
  )
}

export default Slider