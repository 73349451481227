import 'react-app-polyfill/ie9';
import 'polyfill-array-includes';
import 'array-find-polyfill';
import smoothScroll from 'smoothscroll-polyfill';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import actions from './actions';
import configureStore from './configureStore';
// import registerServiceWorker from './registerServiceWorker';
import './index.css';
import "moment/locale/ru";
import moment from "moment";
import CartIconContainer from './containers/CartIconContainer';
import SuggestedDomainsContainer from './containers/SuggestedDomainsContainer';

import { GlobalStyle } from './components/GlobalStyle';
import DnsContainer from './containers/DnsContainer';
import TariffsContainer from './containers/TariffsContainer';
import Slider from "./components/Slider";
import DomainHistoryContainer from "./containers/DomainHistoryContainer";

moment.locale('ru');
smoothScroll.polyfill();

const store = configureStore();

/**
 * Fetching data
 */
const loadUserDataResultAction = store.dispatch(actions.loadUserData({
  domains: !!document.getElementById('app__SuggestedDomains')
}));

/**
 * render helper
 */
const _renderProvider = (Comp, id, cb) => {
  const el = document.getElementById(id);
  if (el) render(<Provider store={store}><Comp /></Provider>, el, cb);
};

const _render = (Comp, id, cb) => {
  const el = document.getElementById(id);
  if (el) render(<Comp {...el.dataset}/>, el, cb);
};

/**
 * Rendering components
 */
document.addEventListener('DOMContentLoaded', () => {
  const globalStylesTag = document.createElement('div');
  document.body.appendChild(globalStylesTag);
  render(<GlobalStyle/>, globalStylesTag);

  _render(DnsContainer, 'module__dns');
  _render(TariffsContainer, 'module__tariffs');
  _render(Slider, 'react__slider');

  (async () => {
    const action = await loadUserDataResultAction;
    const { user } = store.getState();

    if (action.errors === void 0) {
      _renderProvider(CartIconContainer, 'app__CartIcon');

      if (user.accessType === 'client') {
        _renderProvider(SuggestedDomainsContainer, 'app__SuggestedDomains');
      }
    }
  })();

  _render(DomainHistoryContainer, 'app__DomainHistory');
});

// registerServiceWorker();

// TODO: use 'flow'
// TODO: write tests
