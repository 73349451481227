import React from 'react';

export const SvgEye = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.875" height="13.406" viewBox="0 0 17.875 13.406">
      <path id="Path_661" data-name="Path 661" d="M8.937,2.719C4,2.719,0,9.334,0,9.334s4,6.79,8.937,6.79,8.937-6.79,8.937-6.79S13.873,2.719,8.937,2.719Zm0,11.171a4.469,4.469,0,1,1,4.469-4.469A4.469,4.469,0,0,1,8.937,13.89Z" transform="translate(0 -2.719)" fill="#fff"/>
      <circle id="Ellipse_10" data-name="Ellipse 10" cx="2.234" cy="2.234" r="2.234" transform="translate(6.703 4.477)" fill="#fff"/>
    </svg>
  )
};

export const SvgArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8.904" height="5.021" viewBox="0 0 8.904 5.021">
      <path id="icon-up" d="M5621.7,231.783l3.021,3.261-3.021,3.643" transform="translate(-230.783 5625.718) rotate(-90)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </svg>
  )
};

export const SvgPlus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.523" height="14.523" viewBox="0 0 14.523 14.523">
      <g id="Group_317" data-name="Group 317" transform="translate(-488.72 -1131.72)">
        <path id="Path_746" data-name="Path 746" d="M8.241,0,0,8.3" transform="translate(501.829 1139.001) rotate(135)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
        <path id="Path_754" data-name="Path 754" d="M8.241,0,0,8.3" transform="translate(495.962 1144.829) rotate(-135)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
      </g>
    </svg>
  )
}