import React, { Component, Fragment } from 'react';
import './index.css';

class SuggestedDomains extends Component {
  state = {
    loadingButtons: []
  };

  componentDidMount() {
    if (!this.props.userDomains.length) return;

    const randomUserDomains = this.props.userDomains
      .map(x => ({ x, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(a => a.x)
      .slice(0, 4);

    if (!randomUserDomains.length) return;

    const limit = Math.floor(16 / randomUserDomains.length);

    // promise chain
    randomUserDomains.reduce((promise, domain) => {
      return promise.then(() => this.props.searchSuggestedDomains(domain, limit));
    }, Promise.resolve());
  }

  render() {
    return (
      <Fragment>
        {!!this.props.domains.length && <div className="heading">Мы подобрали для вас свободные домены, которые могут быть интересны:</div>}
        <div className="suggested-domains">
          {!!this.props.domains.length && this.props.domains.map(domain => (
            <div className="suggested-domains__item" key={domain.name}>
              <div className="suggested-domains__name">{domain.name}</div>
              <div className="suggested-domains__price">{domain.price} руб.</div>
              <button type="button" disabled={this.state.loadingButtons.includes(domain.name)} className={`domain-btn ${this.state.loadingButtons.includes(domain.name) ? 'domain-btn--loading' : ''} ${this.isDomainInCart(domain) ? 'domain-btn--selected' : 'domain-btn--available'}`} onClick={() => {
                this.handleCartButtonClick(domain);
              }}>{this.isDomainInCart(domain) ? 'В корзине' : 'Купить'}</button>
            </div>
          ))}
          {this.props.loading && <div className="suggested-domains__item suggested-domains__item--loading" />}
        </div>
      </Fragment>
    );
  }

  handleCartButtonClick(domain) {
    this.setState({
      loadingButtons: [ ...this.state.loadingButtons, domain.name ]
    }, () => {
      Promise.resolve()
        .then(() => {
          if (this.isDomainInCart(domain)) {
            return this.props.removeFromCart(domain);
          }
          return this.props.addToCart(domain);
        })
        .then(() => {
          this.setState({
            loadingButtons: this.state.loadingButtons.filter(name => name !== domain.name)
          });
        });
    });
  }

  isDomainInCart({ name }) {
    return !!this.props.cart.find(domain => domain.name === name);
  }
}

export default SuggestedDomains;
