import styled, {css} from 'styled-components'

const Wrapper = styled.div`
  background-color: #fff
  padding: 40px;
  border: 1px solid #DDDDDD;
  border-radius: 0.3125em;
  margin: 1% 0 0;
  
  ${props => props.view === 'tiny' && css`
    background-color: inherit;
    padding: 0;
    margin: 0 0 40px;
    border: none;
    &:last-child{
      margin-bottom: 0;
    }
  `}
`;

export default Wrapper;