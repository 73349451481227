import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';
import actions from './actions';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const eCommerce = store => next => action => {
  if (action.type === actions.ADD_TO_CART_REQUEST) {
    try {
      /**
       * Yandex.Metrika ecommerce
       */
      window.dataLayer.push({
        "ecommerce": {
          "add": {
            "products": [
              {
                "id": action.payload.item.tld,
                "name": action.payload.item.tld,
                "variant": action.payload.item.name,
                "price": action.payload.item.price,
                "category": "Домены",
                "quantity": 1
              }
            ]
          }
        }
      });
    } catch (e) {
      console.warn(e);
    }
  }

  if (action.type === actions.REMOVE_FROM_CART_REQUEST) {
    try {
      /**
       * Yandex.Metrika ecommerce
       */
      window.dataLayer.push({
        "ecommerce": {
          "remove": {
            "products": [
              {
                "id": action.payload.item.tld,
                "name": action.payload.item.tld,
                "variant": action.payload.item.name,
                "price": action.payload.item.price,
                "category": "Домены",
                "quantity": 1
              }
            ]
          }
        }
      });
    } catch (e) {
      console.warn(e);
    }
  }

  return next(action);
};

const countersEvents = state => next => action => {
  if (
    action.type
    && [
      actions.ADD_TO_CART_REQUEST,
      actions.REMOVE_FROM_CART_REQUEST
    ].includes(action.type)
  ) {
    try {
      window.yaCounter24402322.reachGoal('PANEL_' + action.type);
    } catch (e) { console.warn(e) }

    try {
      window.ga('send', 'event', 'event', 'PANEL_' + action.type);
    } catch (e) { console.warn(e) }
  }

  return next(action);
};

export default initialState => createStore(
  reducer,
  initialState,
  composeEnhancers(
    applyMiddleware(
      thunk,
      eCommerce,
      countersEvents
    )
  )
);