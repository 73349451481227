import React from 'react';
import './index.css';

const CartIcon = props => (
  <a href="/order" className="cart-icon">
    <i className={`cart-icon__badge ${props.cartAmount ? 'cart-icon__badge--visible' : ''}`}>{props.cartAmount}</i>
    <svg className="cart-icon__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.1 19.2" width="22" height="18" preserveAspectRatio="xMidYMid meet">
      <path fill="#df373e" d="M6.1,19.2c-1.5,0-2.8-1.2-2.8-2.8c0-1.5,1.2-2.8,2.8-2.8s2.8,1.2,2.8,2.8C8.8,18,7.6,19.2,6.1,19.2 L6.1,19.2z M6.1,14.7c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8C7.8,15.5,7,14.7,6.1,14.7L6.1,14.7z"/>
      <path fill="#df373e" d="M17.3,19.2c-1.5,0-2.8-1.2-2.8-2.8c0-1.5,1.2-2.8,2.8-2.8c1.5,0,2.8,1.2,2.8,2.8 C20.1,18,18.8,19.2,17.3,19.2L17.3,19.2z M17.3,14.7c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8 C19.1,15.5,18.3,14.7,17.3,14.7L17.3,14.7z"/>
      <path fill="#df373e" d="M15,17H8.7c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5H15c0.3,0,0.5,0.2,0.5,0.5 C15.5,16.7,15.3,17,15,17L15,17z"/>
      <path fill="#df373e" d="M6.4,14.6c0,0-0.1,0-0.1,0C6,14.5,5.9,14.2,6,14l0.6-2L3.2,1.8L0.4,1C0.1,0.9-0.1,0.6,0,0.4 C0.1,0.1,0.4-0.1,0.6,0l3,0.9C3.8,0.9,4,1.1,4,1.2l3.6,10.6c0,0.1,0,0.2,0,0.3l-0.7,2.1C6.8,14.5,6.6,14.6,6.4,14.6L6.4,14.6z"/>
      <path fill="#df373e" d="M19.8,12.5H7.1c-0.2,0-0.4-0.1-0.5-0.4L4.2,4.3c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.2h18 c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.4l-2,7.1C21,12,20.5,12.5,19.8,12.5L19.8,12.5z M7.5,11.5h12.4c0.1,0,0.2,0,0.2-0.2 c0,0,0-0.1,0-0.1L22,4.6H5.3L7.5,11.5L7.5,11.5z"/>
    </svg>
  </a>
);

export default CartIcon;
