import React, {Component,Fragment} from 'react'

import Period from '../../components/all/Period'
import OwnListDns from '../../components/all/DnsSettingComponents/OwnListDns'
import RegListDns from '../../components/all/DnsSettingComponents/RegListDns'
import Popup from "../../components/all/Popup";
// import Breadcrumb from '../../components/all/Breadcrumb'
import Mail from '../../components/all/Mail'
import Comments from "../../components/all/Comments";

import Wrapper from '../../components/all/Wrapper'
import Headline from '../../components/all/Headline'
import FlexBlock from '../../components/all/FlexBlock'
import Button, { ButtonProlongation } from '../../components/all/Button'
import { Text } from '../../components/all/Typography'
import ExpansionPanel, { themeExpansionPanelOwnListDns,themeExpansionPanelIcon } from '../../components/all/ExpansionPanel'
// import Link, { themeLinkIcon } from '../../components/all/Link'
import Switcher from '../../components/all/Switcher'
import Notification from '../../components/all/Notification'

import { SvgMail } from "../../components/all/SvgIcon";


import getNextId from '../../utils/getNextId'

import {colorGray} from "../../constants/variables";
import Link from "../../components/all/Link";

class DnsContainer extends Component {
  state = {
    user: '',
    domain: '',
    expire: 0,
    settings: {
      delegation: false,
      justice: false
    },
    dateRegistration: {
      start: '',
      finish: ''
    },
    addInfo: {
      administrator: {
        name: '',
        link: ''
      },
      contract: {
        val: '',
        link: ''
      }
    },
    expansion: {
      ownlistdns: true,
      reglistdns: false
    },
    autoProlongation: false,
    ownListDns: [],
    regListDns: [],
    comments: [],
    notification: [],
    popup: {
      show: false,
      data: {
        func: '',
        content: '',
        body: ''
      }
    }
  };

  componentDidMount(){
    let allDataFromServer = {
      user: 'admin',
      domain: 'dolmenus',
      expire: 54,
      settings: {
        delegation: true,
        justice: false
      },
      dateRegistration: {
        start: '22.09.2017',
        finish: '22.09.2019'
      },
      addInfo: {
        administrator: {
          name: 'ООО «Регистратор»',
          link: '#'
        },
        contract: {
          val: 'CL5974-ORG-DMS',
          link: '#'
        }
      },
      autoProlongation: false,
      ownListDns: ['ns1.domenus.ru','ns5.hoster.ru'],
      regListDns: [
        {
          id: 1,
          host: 'mailru._domainkey',
          type: 'TXT',
          value: '«v=DKIM1; k=rsa;p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDRbaYpYr1EYpqI+1G5g0Icc6lMZC7H3sBXfXWxaKEOBBlmcF4XNJNJypRxvGOyua0Kz',
          priority: 1800,
        },
        {
          id: 2,
          host: 'mailru._hoster',
          type: 'NETTXT',
          value: '192.168.0.102',
          priority: 3600,
        },
        {
          id: 3,
          host: 'mailru._datahouse',
          type: 'NETTXT',
          value: '192.168.0.102',
          priority: 3600,
        }
      ],
      comments: [
        {
          date: '12.03.2019',
          messages: [
            {
              id: 1,
              time: '15:00',
              user: 'Мороз Роман',
              comment: 'Самосогласованная модель предсказывает, что при определенных условиях пульсар переворачивает резонатор. Самосогласованная модель предсказывает, что при определенных условиях экситон нейтрализует гравитационный солитон. Взвесь, даже при наличии сильных аттракторов, катастрофично стабилизирует кварк. Поверхность когерентно ускоряет кварк. Мишень синхронизует фотон.'
            },
            {
              id: 2,
              time: '07:34',
              user: 'Мороз Роман',
              comment: 'Самосогласованная модель предсказывает, что при определенных условиях пульсар переворачивает резонатор. Самосогласованная модель предсказывает, что при определенных условиях экситон нейтрализует гравитационный солитон.'
            }
          ]
        },
        {
          date: '26.07.2018',
          messages: [
            {
              id: 1,
              time: '15:00',
              user: 'Серегин Санек норм парень',
              comment: 'Самосогласованная модель предсказывает, что при определенных условиях пульсар переворачивает резонатор. Самосогласованная модель предсказывает, что при определенных условиях экситон нейтрализует гравитационный солитон. Взвесь, даже при наличии сильных аттракторов, катастрофично стабилизирует кварк. Поверхность когерентно ускоряет кварк. Мишень синхронизует фотон.'
            },
            {
              id: 2,
              time: '07:34',
              user: 'Мороз Роман',
              comment: 'Самосогласованная модель предсказывает, что при определенных условиях пульсар переворачивает резонатор. Самосогласованная модель предсказывает, что при определенных условиях экситон нейтрализует гравитационный солитон.'
            }
          ]
        },
        {
          date: '24.07.2017',
          messages: [
            {
              id: 1,
              time: '15:00',
              user: 'Серегин Санек норм парень',
              comment: 'Самосогласованная модель предсказывает, что при определенных условиях пульсар переворачивает резонатор. Самосогласованная модель предсказывает, что при определенных условиях экситон нейтрализует гравитационный солитон. Взвесь, даже при наличии сильных аттракторов, катастрофично стабилизирует кварк. Поверхность когерентно ускоряет кварк. Мишень синхронизует фотон.'
            },
            {
              id: 2,
              time: '07:34',
              user: 'Мороз Роман',
              comment: 'Самосогласованная модель предсказывает, что при определенных условиях пульсар переворачивает резонатор. Самосогласованная модель предсказывает, что при определенных условиях экситон нейтрализует гравитационный солитон.'
            }
          ]
        }
      ]
    };

    let ownListDnsTransform = allDataFromServer.ownListDns.map((el,i) => {
      return {
        id: getNextId(),
        name: 'DNS ' + (++i),
        value: el
      }
    });

    this.setState({
      user: allDataFromServer.user,
      domain: allDataFromServer.domain,
      expire: allDataFromServer.expire,
      settings: allDataFromServer.settings,
      dateRegistration: {...allDataFromServer.dateRegistration},
      addInfo: {...allDataFromServer.addInfo},
      autoProlongation: allDataFromServer.autoProlongation,
      ownListDns: ownListDnsTransform,
      regListDns: [...allDataFromServer.regListDns],
      comments: [...allDataFromServer.comments]
    })
  };

  render() {
    return (
      <Fragment>
        <Notification notice={this.state.notification}/>
        {/*<Breadcrumb activeLink={2} />*/}

        <Headline size='xl' color='red'>{this.state.domain}</Headline>

        <Wrapper>
          <Headline>Информация</Headline>

          <FlexBlock align-items="stretch"
                     flex-wrap="wrap"
          >
            <Period start={this.state.dateRegistration.start} finish={this.state.dateRegistration.finish} />

            <FlexBlock flex-direction="column"
                       align-items="600:center|1201:stretch"
                       justify-content="space-between"
                       margin="600:0 0 20px 0|1201:0 28px 20px 20px"
                       flex="600:100% 0 0|1201:none"
            >
              <ButtonProlongation clickAction={this.autoProlongation(this.state.domain)}
                                  on={this.state.autoProlongation}/>
              <Button onClick={this.showPopup(
                'apply',
                this.prolongation(this.state.domain),
                {
                  head: 'Продлить на 1 год?'
                }
              )}
                      disabled={this.state.expire > 60}
              >Продлить на 1 год</Button>
            </FlexBlock>

            <FlexBlock className="dns_added-info"
                       flex-direction="column"
                       justify-content="space-between"
                       align-items="stretch"
                       margin="600:0 auto|1201:0 0 20px 0"
            >
              <Text weight={600}>Дополнительная информация</Text>
              {this.state.user === 'admin' && <Text color={colorGray} cursor="pointer" onClick={this.showWhois(this.state.domain)}>Посмотреть информацию Whois</Text>}

              <table className="table-common">
                <tbody>
                <tr>
                  <td><Text color="rgba(70,70,70,.55)">Администратор</Text></td>
                  <td>
                    <Text>{this.state.addInfo.administrator.name}</Text>
                    {this.state.user === 'admin' && <Link margin="0 0 0 10px" href={this.state.addInfo.administrator.link} common>Идентифицировать</Link>}
                  </td>
                </tr>
                <tr>
                  <td><Text color="rgba(70,70,70,.55)">Номер договора (Ник-Хендл)</Text></td>
                  <td>
                    <Text>{this.state.addInfo.contract.val}</Text>
                    {this.state.user === 'admin' && <Link margin="0 0 0 10px" href={this.state.addInfo.contract.link} common>Войти в клиента</Link>}
                  </td>
                </tr>
                </tbody>
              </table>

            </FlexBlock>
          </FlexBlock>
        </Wrapper>

        {/*{this.state.user === 'admin' &&*/}
          <Fragment>
            <Wrapper>
              <Headline view="admin">Суперадмин</Headline>

              <Wrapper view="tiny">
                <Headline view="arrow">Настройки</Headline>
                <FlexBlock flex-wrap="wrap">
                  <Switcher active={this.state.settings.delegation} text="Разделегирование"  handlerSwitch={this.handlerSwitch('delegation')}/>
                  <Switcher active={this.state.settings.justice} text="Судебный"  handlerSwitch={this.handlerSwitch('justice')}/>
                </FlexBlock>
              </Wrapper>

              <Wrapper view="tiny">
                <Headline view="arrow">Письма и уведомления</Headline>
                <ExpansionPanel title="Отправить уведомление о верификации" theme={themeExpansionPanelIcon(<SvgMail/>)}>
                  <Mail />
                </ExpansionPanel>
              </Wrapper>

              <Wrapper view="tiny">
                <Headline view="arrow">Комментарии</Headline>
                <Comments key={this.state.comments.length} comments={this.state.comments} addComment={this.addComment}/>
              </Wrapper>
            </Wrapper>
          </Fragment>
        {/*}*/}

        {/*{this.state.user === 'client' &&*/}
          <Fragment>
            <Wrapper>
              <Headline>Настройка DNS</Headline>

              <ExpansionPanel title="Собственный список DNS"
                              theme={themeExpansionPanelOwnListDns}
                              show={this.state.expansion.ownlistdns}
                              onClick={() => this.setState({
                                  expansion: {reglistdns: false}}
                                )
                              }
              >
                <OwnListDns listDns={this.state.ownListDns}
                            key={this.state.ownListDns.length}
                            msgNotice={this.msgNotice}
                />
              </ExpansionPanel>

              <ExpansionPanel title="DNS регистратора Domenus.ru: ns1.domenus.ru и ns2.domenus.ru"
                              show={this.state.expansion.reglistdns}
                              onClick={() => this.setState({
                                  expansion: {ownlistdns: false}}
                                )
                              }
              >
                <RegListDns regListDns={this.state.regListDns}
                            key={this.state.regListDns.length}
                            msgNotice={this.msgNotice}
                />
              </ExpansionPanel>
            </Wrapper>

            {/*<Wrapper>
              <Headline>Дополнительные услуги</Headline>
              <FlexBlock justify-content="space-between">
                <Link href="#" theme={themeLinkIcon} icon="standard">Веб-редирект</Link>
                <Link href="#" theme={themeLinkIcon} icon="standard">Пример</Link>
                <Link href="#" theme={themeLinkIcon} icon="standard">Пример</Link>
                <Link href="#" theme={themeLinkIcon} icon="standard">Пример</Link>
              </FlexBlock>
            </Wrapper>*/}
          </Fragment>
        {/*}*/}

        <Popup showPopup={this.state.popup.show}
               closePopup={this.closePopup}
               func={this.state.popup.data.func}
               content={this.state.popup.data.content}
               body={this.state.popup.data.body}
        />
      </Fragment>
    )
  }

  msgNotice = msg => {
    const timerId = setTimeout(() => this.removeNotice(timerId), 3000);

    this.setState({
      notification: [
        ...this.state.notification,
        {msg, timerId}
      ]
    });
  };

  removeNotice = timerId => {
    this.setState({
      notification: this.state.notification.filter(notice => timerId !== notice.timerId)
    });
  };

  prolongation = domain => () => {
    //server
    this.msgNotice('Продлили на год')
    this.setState({
      expire: 365
    });
    this.closePopup();
  };

  autoProlongation = val => () => {
    //server
    this.state.autoProlongation ? this.msgNotice('Автопродление отключено') : this.msgNotice('Автопродление включено');
    this.setState({
      autoProlongation: !this.state.autoProlongation,
    });
  };

  showPopup = (content, func, body) => () => {
    this.setState({
      popup: {
        show: true,
        data: {content, func, body}
      }
    })
  };
  closePopup = () => {
    this.setState({
      popup: {
        show: false,
        data: {content: '', func: ''}
      }
    })
  };
  
  //Whois info
  showWhois = domain => () => {
    // обращаемся к серверу передаем domain
    // Результат например - whois_info
    let whois_info = 'Сюда запишется весь результат пришедший с сервака про whois';
    
    this.showPopup('text', null, {
        head: 'Информация whois для ' + domain,
        content: whois_info
      })()
  };
  
  handlerSwitch = val => () => {
    //server --> val and this.state.settings[val]
    this.setState({
      settings: {
        ...this.state.settings,
        [val]: !this.state.settings[val]
      }
    })
  };

  addComment = newComment => {
    this.setState({
      comments: [
        newComment,
        ...this.state.comments,
      ]
    })
  }
}

export default DnsContainer